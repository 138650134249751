import styles from './FaqGroup.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import useResponsive from '#hooks/useResponsive';
import FaqItem from '../FaqItem';

type Item = {
  questions: string[];
  answers: string[];
};

type Props = {
  items: Item[];
};

const FaqGroups: FC<Props> = ({ items }) => {
  const [responsiveItems, setResponsiveItems] = useState<Array<Item>>([]);
  const isTablet = useResponsive('tablet');

  useEffect(() => {
    const mobileItems: Item[] = [{ questions: [], answers: [] }];
    items.map((item) => {
      mobileItems[0].questions.push(...item.questions);
      mobileItems[0].answers.push(...item.answers);
    });

    if (!isTablet) {
      setResponsiveItems(items);
    } else setResponsiveItems(mobileItems);
  }, [isTablet]);

  return (
    <div className={styles.container} itemScope itemType="https://schema.org/FAQPage">
      {responsiveItems.map((item, groupIndex) => (
        <FaqGroup item={item} groupIndex={groupIndex} key={groupIndex} />
      ))}
    </div>
  );
};

type GroupProps = {
  item: Item;
  groupIndex: number;
};

const FaqGroup: FC<GroupProps> = ({ item, groupIndex }: GroupProps) => {
  const [activeElem, setActiveElem] = useState<number | null>(null);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { scrollHeight } = content.current as HTMLDivElement;
    if (scrollHeight) {
      setActiveElem(groupIndex * 10);
    }
  }, []);

  return (
    <div className={styles.group} ref={content} key={groupIndex}>
      {item.questions.map((question, index) => (
        <FaqItem
          key={index}
          question={question}
          answer={item.answers[index]}
          index={groupIndex * 10 + index}
          isActive={activeElem === groupIndex * 10 + index}
          setActiveElem={setActiveElem}
        />
      ))}
    </div>
  );
};

export default FaqGroups;
